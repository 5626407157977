jQuery( document ).ready(function($) {

    if (window.location.hash){
        let target = '#main section:not(#' + window.location.hash.substring(1) + ")";
        $(target).hide();
    } else {
        $("#main section:not(#home)").hide();
    }

    $('.menu-toggle').on('click', function(e){
        e.preventDefault();
        $('.menu').toggleClass('active');
    })

    $('.page-switch').on('click', function(e){
        e.preventDefault();
        $("#main section").hide();
        $($(this).attr('href')).fadeIn();
    })

    $('.newsletter').on('submit', function(e) {
        e.preventDefault();
        const DATA = $(this).serialize();

        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            dataType: 'json',
            data: DATA,
            xhrFields: {
                withCredentials: false,
            },
            success: function (data) {
                $('.inputs-wrap').html('<p class="newsletter-thanks">Thanks for signing up!</p>');
            },
            error: function (err) {
                console.log(err);
            }
        });
    });

    $(".mail-post").hide();
    $(".mail-post:first-child").show();
    $(".selector a").on('click', function(e){
        e.preventDefault();
        let target = $(this).attr('href');
        $(".mail-post").hide();
        $(target).show();
        $('.mailbox').show();
    })
});